import React, { useState, useContext } from 'react';
import {
  Flex, Box, Text, Button,
} from 'rebass';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { navigate } from '@reach/router';
import { format } from 'date-fns/esm';
import { FaCog, FaSearch } from 'react-icons/fa';
import Layout from '../components/Layout';
import { Colors } from '../theme';
import CardFoodRestaurant from '../components/CardFoodRestaurant';
import useLocateMe from '../components/useLocateMe';
import LocateMeButton from '../components/LocateMeButton';
import SEO from '../components/SEO';
import UserLocationContext from '../context/UserLocationContext';

const SEARCH_FOOD_IMAGES = gql`
  query searchFoodImages(
    $query: String
    $city: City!
    $userLatitude: Float
    $userLongitude: Float
    $offset: Int
    $limit: Int
    $day: String
  ) {
    searchFoodImages(
      city: $city
      query: $query
      userLatitude: $userLatitude
      userLongitude: $userLongitude
      offset: $offset
      limit: $limit
    ) {
      keyword
      items {
        id
        type
        imageUrl
        title
        price
        description
        photoBy
        Restaurant {
          id
          name
          logo
          tradingHours(day: $day)
          location {
            coordinates
          }
          cuisineType
        }
      }
    }
  }
`;

const SearchPage = ({ location }) => {
  const { latitude, longitude } = useContext(UserLocationContext);
  const [loadingLocation, locateUser] = useLocateMe();
  const [noMoreResults, setNoMoreResults] = useState(false);
  const qs = new URLSearchParams(location.search);
  const q = qs.get('query');
  const [input, setInput] = useState(q);
  const [query, setQuery] = useState(q);
  if (!query) {
    return null;
  }
  return (
    <Layout headerRight="restaurants">
      <SEO
        pageTitle="Scopri dove si trova il tuo piatto preferito - Mangiami"
        pageDescription="Hai una voglia matta di.. e non sai dove poterlo trovare? Utilizza il nostro motore di ricerca integrato per scoprire dove mangiare il tuo piatto preferito."
        pageImage="https://d12unveku4b8zl.cloudfront.net/restaurants/lugano/0000%20static/mangiami%20thumbnail%20-%20final.png"
        pageUrl={`/search?query=${query}`}
      />
      <Box bg="#f2f2f2">
        <Flex flexDirection="column" mx="auto" style={{ maxWidth: 1200 }}>
          <Query
            query={SEARCH_FOOD_IMAGES}
            variables={{
              query,
              city: 'lugano',
              offset: 0,
              limit: 9,
              userLatitude: latitude,
              userLongitude: longitude,
              day: format(new Date(), 'EEEE'),
            }}
            fetchPolicy="cache-and-network"
            notifyOnNetworkStatusChange
          >
            {({
              loading, error, data, refetch, fetchMore, networkStatus,
            }) => {
              if (loadingLocation || (loading && networkStatus === 1)) {
                return (
                  <Flex
                    py={[4, 6]}
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <FaCog size={28} className="icon-spin" />
                    <Text py={3}>Caricamento</Text>
                  </Flex>
                );
              }
              if (error) {
                return (
                  <Flex style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                    <Text>Errore durante il caricamento foto</Text>
                    <Button varint="primary" onClick={() => refetch().catch(() => {})}>
                      <Text color="#fff">Riprova</Text>
                    </Button>
                  </Flex>
                );
              }
              const { searchFoodImages: suggestions } = data;
              if (!suggestions.length) {
                return (
                  <Flex my={5} flexDirection="column" alignItems="center" justifyContent="center">
                    <Text fontSize={4}>
                      Sfortunatamente non abbiamo trovato il tuo piatto, vuoi cercarne un altro?
                    </Text>
                    <Flex
                      py={4}
                      flexDirection="row"
                      flexWrap="wrap"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Box style={{ position: 'relative' }} width={[1, 500, 600]} px={[1, 2]}>
                        <input
                          placeholder="spaghetti alla carbonara"
                          className="big-search-bar"
                          value={input}
                          onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                              setQuery(e.target.value);
                              navigate(`/search/?query=${encodeURIComponent(e.target.value)}`, {
                                replace: true,
                              });
                            }
                          }}
                          onChange={e => setInput(e.target.value)}
                        />
                        <div
                          style={{
                            position: 'absolute',
                            right: 0,
                            top: 0,
                            bottom: 0,
                          }}
                        >
                          <Button
                            onClick={() => {
                              setQuery(input);
                              navigate(`/search/?query=${encodeURIComponent(input)}`, {
                                replace: true,
                              });
                            }}
                            width={80}
                            variant="primary"
                            style={{
                              height: '100%',
                              marginTop: 0,
                              borderTopRightRadius: 36,
                              borderBottomRightRadius: 36,
                            }}
                          >
                            <FaSearch size={20} style={{ marginTop: 0 }} />
                          </Button>
                        </div>
                      </Box>
                    </Flex>
                  </Flex>
                );
              }
              return (
                <Flex flexDirection="column">
                  {!(latitude && longitude) && (
                    <Box py={3}>
                      <Flex
                        py={3}
                        flexWrap="wrap"
                        flexDirection="row"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Text mb={[2, 0]} width={[1, 'auto']} textAlign="center">
                          Vuoi vedere i piatti più vicini a te?
                        </Text>
                        <LocateMeButton
                          onClick={locateUser}
                          mx={2}
                          variant="outline"
                          loading={loadingLocation}
                        />
                      </Flex>
                      <Text textAlign="center" fontSize={1}>
                        (di default i piatti sono ordinati per distanza da Piazza Riforma, 6900
                        Lugano)
                      </Text>
                    </Box>
                  )}
                  {suggestions.map(({ keyword, items }, k) => (
                    <Flex key={keyword} width={1} flexDirection="column" flexWrap="wrap">
                      {k === 0 && (
                        <Text py={[3, 4]} fontSize={[4, 5]} textAlign="center" width={1}>
                          Risultati per: {keyword}
                        </Text>
                      )}
                      {k > 0 && (
                        <Text py={[3, 4]} fontSize={[4, 5]} textAlign="center" width={1}>
                          Potrebbe interessarti... {keyword}
                        </Text>
                      )}
                      <Flex key={keyword} flexDirection="row" flexWrap="wrap">
                        {items.map(FoodImage => (
                          <Box key={FoodImage.id} px={[2, 3]} py={[1, 3]} width={[1, 1, 1 / 3]}>
                            <CardFoodRestaurant {...FoodImage} />
                          </Box>
                        ))}
                      </Flex>
                    </Flex>
                  ))}
                  {loading && networkStatus !== 1 ? (
                    <Flex py={3} flexDirection="row" justifyContent="center">
                      <FaCog size={24} className="icon-spin" />
                    </Flex>
                  ) : (
                    (suggestions.length === 1 && suggestions[0].items.length % 9 === 0)
                    && (!noMoreResults && suggestions.length === 1 && (
                      <Flex py={3} flexDirection="row" justifyContent="center">
                        <Button
                          onClick={() => {
                            fetchMore({
                              variables: {
                                offset: suggestions[0].items.length,
                              },
                              updateQuery: (prev, { fetchMoreResult }) => {
                                if (
                                  !fetchMoreResult
                                  || fetchMoreResult.searchFoodImages.length === 0
                                ) {
                                  setNoMoreResults(true);
                                  return prev;
                                }
                                const result = {
                                  ...prev,
                                  searchFoodImages: [
                                    {
                                      ...prev.searchFoodImages[0],
                                      items: [
                                        ...prev.searchFoodImages[0].items,
                                        ...fetchMoreResult.searchFoodImages[0].items,
                                      ],
                                    },
                                  ],
                                };
                                return result;
                              },
                            });
                          }}
                          variant="outline"
                        >
                          Carica più piatti
                        </Button>
                      </Flex>
                    ))
                  )}
                </Flex>
              );
            }}
          </Query>
          <Box py={5}>
            <Text color={Colors.GREY} textAlign="center">
              I contenuti presenti sulla Piattaforma, in particolare le fotografie dei cibi e le
              schede dei ristoranti, sono pubblicati a titolo meramente informativo e senza
              garanzia, per cui alcuni cibi, bevande e/o servizi (incluso il prezzo) potrebbero non
              essere sempre aggiornati e/o disponibili.
            </Text>
          </Box>
        </Flex>
      </Box>
    </Layout>
  );
};

export default SearchPage;
